import React from 'react'
import {Link, graphql} from 'gatsby'
import Layout from '../components/layout'
import ArticleList from "../components/article-list";
import {getExerpt} from "../environment";
import SEO from "../components/seo";

const AllEnTemplate = ({data, pageContext}) => (
    <Layout lang="en" pagePath="/all">
      <SEO title="All Posts" keywords={[`Pikon log`, `Pikon Inc.`, `Tech Blog`]} lang="en"/>
      {(() => {
        if (data.allStrapiBlog.edges.length !== 0) {
          return (
              <ArticleList
                  blogs={data.allStrapiBlog.edges}
                  type="enumeration"
                  lang="en"
                  heading="All Posts"
                  pages={pageContext.humanPageNumber + " / " + pageContext.numberOfPages}
                  pageContext={pageContext}
                  pagePath="/all"
              />
          )
        }
        else {
          return (
              <article className="article">
                <section className="article__article-body">
                  <p style={{textAlign: "center", paddingTop:"1rem"}}>No articles.</p>
                </section>
              </article>
          )
        }
      })()}
    </Layout>
)

export default AllEnTemplate

export const query = graphql`
  query AllEnTemplate($skip: Int!, $limit: Int!){
    allStrapiBlog(
      skip: $skip,
      limit: $limit,
      filter:{
        status:{
          eq:true
        },
        isTranslated:{
          eq:true
        }
      },
      sort:{
        fields: [publishDate, id]
  	    order: [DESC, DESC]
      }
    ){
      edges{
        node{
          id
          title
          title_en
          publishDate(formatString: "MMM DD, YYYY")
          strapiId
          slug
          status
          isTranslated
          category {
            id
            name
            name_en
            slug
          }
          featuredImage {
            id
            childImageSharp{
              fixed(width: 160,height: 120) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }  
`